import { FormEvent, SetStateAction, useState } from "react";
import AllProductVisiteur from "../../../Views/Utilisateure/visiteur/AllProductVisiteur";

export default function FilterByDate() {
    const [date, setDate] = useState<string>("");
    const [min, setMin] = useState<string>("");
    const [max, setMax] = useState<string>("");


    const handleSubmitDate = async (e: FormEvent) => {
        e.preventDefault();
        // Add any additional logic for form submission if needed
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // Add logic for handling the submit action
    };

    return (
        <>
            <AllProductVisiteur
                handleSubmitDate={handleSubmitDate}
                date={date}
                setDate={setDate}
                min={min}
                max={max}
                setMin={setMin}
                setMax={setMax}
                handleSubmit={handleSubmit}
            />
        </>
    );
}
