import axios , {AxiosError} from "axios";
import { useParams } from "react-router-dom";
import AfficheProduit from "../../Views/Admin/HomeAdmin/AfficheProduit";
import { SetStateAction, useEffect, useState } from "react";

export default function AfficheProduitService(){
  const [Designation,setDesignation] = useState<string>("");
  const [IdArticle,setIdArticle] = useState<number>(0);
  const [PrixVenteArticleTTC,setPrixVenteArticleTTC] = useState<any>("");
  const [RefArticle,setRefArticle] = useState<string>("");
  const [urlImage,setimage] = useState<string>("");
  const [Description,setDescription] = useState<string>("");
  const [stock,setstock] = useState<string>("");
  const [Remise,setRemise] = useState<string>("");
  const [DesignationAr,setDesignationAr] = useState<string>("");
  const [LibelleSubstitut,setLibelle] = useState<string>("")
  const [QantityMagasin,setQantityMagasin] = useState<string>("")
  const [Qte,setQte] = useState<string>("")
  const [PrixNetHT,setPrixNetHT] = useState<string>("")
  const [prix_ht_3_magasin,setPrixHt3] = useState<string>("")
  const [prix_ttc,setPrixTtc] = useState<string>("")
  const { id } = useParams<{ id?: string}>(); 
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(()=>{
        fetchProduct();
    },[])
    const fetchProduct=async()=>{
        try {
          await axios.get(`${process.env.REACT_APP_PHP_APP_URL}/articles/${id}`)
          .then(({data})=>{
            setDesignationAr(data.DesignationAr);
            setimage(data.urlImage);
            setDesignation(data.Designation);
            setLibelle(data.LibelleSubstitut);
            setRefArticle(data.RefArticle);
            setstock(data.QteQtock)
            setPrixNetHT(data.PrixNetHT)
            setLoading(false);

        })} catch (error: AxiosError | any) {
          if (error.response?.status === 442) {
          console.log("good data");   
          setLoading(false);

          } else {
            console.log("error");
          }
        }
      }
    return <>
        <AfficheProduit
        loading={loading}
        IdArticle={IdArticle}
        Designation={Designation}
        PrixVenteArticleTTC={PrixVenteArticleTTC}
        LibelleSubstitut={LibelleSubstitut}
        RefArticle={RefArticle}
        urlImage={urlImage}
        DesignationAr={DesignationAr}
        Description={Description}
        stock={stock}
        QantityMagasin={QantityMagasin}
        Qte={Qte}
        Remise={Remise}
        PrixNetHT={PrixNetHT}
        prix_ht_3_magasin={prix_ht_3_magasin}
        prix_ttc={prix_ttc}
        Unite={""} setDesignation={function (value: SetStateAction<string>): void {
          throw new Error("Function not implemented.");
        } } setPrixVenteArticleTTC={function (value: SetStateAction<string>): void {
          throw new Error("Function not implemented.");
        } } setDescription={function (value: SetStateAction<string>): void {
          throw new Error("Function not implemented.");
        } } setImage={function (value: SetStateAction<File | null>): void {
          throw new Error("Function not implemented.");
        } } setstock={function (value: SetStateAction<string>): void {
          throw new Error("Function not implemented.");
        } } setRefARticle={function (value: SetStateAction<string>): void {
          throw new Error("Function not implemented.");
        } } setUnite={function (value: SetStateAction<string>): void {
          throw new Error("Function not implemented.");
        } } setprix_ht_2_magasin={function (value: SetStateAction<string>): void {
          throw new Error("Function not implemented.");
        } } setprix_ht_3_magasin={function (value: SetStateAction<string>): void {
          throw new Error("Function not implemented.");
        } } setprix_ht_1_magasin={function (value: SetStateAction<string>): void {
          throw new Error("Function not implemented.");
        } } setquantité={function (value: SetStateAction<string>): void {
          throw new Error("Function not implemented.");
        } } setNomClient={function (value: SetStateAction<string>): void {
          throw new Error("Function not implemented.");
        } } setAdresse={function (value: SetStateAction<string>): void {
          throw new Error("Function not implemented.");
        } } setTotalCommandeHT={function (value: SetStateAction<string>): void {
          throw new Error("Function not implemented.");
        } } setTotalRemise={function (value: SetStateAction<string>): void {
          throw new Error("Function not implemented.");
        } }        />
    </>
}