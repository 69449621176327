import Sidebare from "../Sidbare/Sidebare";
import ImageProduit2 from "../IMG/2.png"
import { Link } from "react-router-dom";
import { ArticleInfo } from "../../../Modeles/ArticleModel";
import { useEffect, useState } from "react";
import axios from "axios";
// import AfficheProductsService, { getProduct } from "../../../Services/Admin/AfficheProductsService";
const AfficheProduit:React.FC<ArticleInfo> = ({
        IdArticle,
        Designation,
        PrixVenteArticleTTC,
        Description,
        urlImage,
        stock,
        RefArticle,
        LibelleSubstitut,
        QantityMagasin,
        prix_ht_1_magasin,
        PrixNetHT,
        prix_ht_3_magasin,
        prix_ttc,
        Qte,
        DesignationAr,
        Remise,
        loading,
    
  }) => {
    const handleGoBack = () => {
        window.history.back();
    };
        return( <>
    <Sidebare/>
    {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '550px', color:"#FFC400" }}>
            <div className="spinner-border custom-spinner" role="status">
            </div>
            <span className="loader-charg">Chargement</span>
          </div>
        ) : (
    <div className="container produit-card mt-5">
    <i onClick={handleGoBack} className="bi bi-arrow-left-short arrow"></i>
    <div className="bar-title">
        <h3>{Designation}{DesignationAr ?` | ${DesignationAr}` :""}</h3>
        <a href=""><Link to={`/articles/${IdArticle}/edit`} className="buts">Changer les infos</Link></a>
    </div>
    <div className="card-item mt-5">
        
        <img className="image-produit"style={{width:"22rem",height:"18rem"}} src={urlImage} alt={urlImage} />
    <div className="item-info">
        <h2 className="price-product mb-5">{PrixNetHT} MAD <span className="pu">/P.U</span></h2>
        {/* <h5 className="message-remise">la remise : {Remise} Mad</h5> */}
        {/* <h6>Prix Ht:<span className="reference-item">{PrixNetHT}</span> </h6> */}
        {/* <h6>Prix Ht 2:<span className="reference-item">{(parseFloat(PrixNetHT || "0")) - (Remise ?? 0)}</span> </h6> */}
     <h6>Réference:<span className="reference-item">{RefArticle}</span> </h6>
     <h6>
  Substitut :
  <span className="reference-item">
    {(Array.isArray(LibelleSubstitut) && LibelleSubstitut.length === 1 && LibelleSubstitut[0].trim() === "") || !LibelleSubstitut
      ? <span>N/A</span>
      : LibelleSubstitut}
  </span>
</h6>        <h6>
  Qte Stock: 
  <span className="quantity-item">
    {stock !== undefined && stock == "0" ? (
      <>
        <span className="stock-épuisé mb-3">stock épuisé <i className="bi ise bi-exclamation-lg"></i></span><br /><br />
        <a className="btn-da mt-5"><i className="bi ida bi-plus-square"></i> Demander l'article</a>
      </>
    ) : (
      stock
    )}
  </span>
</h6>
  

    </div>

    </div>
    <div className="description mt-5 mb-5" >
        <p className="mt-3 ">{Description}</p>
    </div>
    <div className="magasins-name mt-5">
        <h6>Quantité et le prix de chaque magasin</h6>

        <div className="trois-prix">
        <h6 >ZenPart: {QantityMagasin ? QantityMagasin : 0}</h6>
        <h6>Prix Ht1 : {prix_ttc?prix_ttc:0}</h6>
        <h6>Prix Ht2 : {prix_ttc?prix_ttc:0}</h6>
        <h6>Prix Ht3 : {prix_ttc?prix_ttc:0}</h6>
        <h6>Prix TTC : {prix_ttc?prix_ttc:0}</h6> 
            </div>
    </div>
    </div>
    )

}
    </>
     )
    
}
export default AfficheProduit;