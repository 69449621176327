import React, { useEffect, useState } from 'react';
import ProductImage from "../../Admin/IMG/2.png";
import "./panier_visiteur.css";
import PanierChrono from '../../../components/PanierChrono';

interface Product {
    id: string;
    name: string;
    imageUrl: string;
    price: string;
    quantity: number;
}

const deleteProduct = (id: string, updateProducts: (products: Product[]) => void) => {
    let arr: Product[] = [];
    const localStorageData = localStorage.getItem('productVisiteur');
    if (localStorageData) {
        arr = JSON.parse(localStorageData);
    }

    const updatedProducts = arr.filter(product => product.id !== id);
    localStorage.setItem('productVisiteur', JSON.stringify(updatedProducts));
    updateProducts(updatedProducts);
};

export default function Panier_visiteur() {
    const handleGoBack = () => {
        window.history.back();
    };

    const [products, setProducts] = useState<Product[]>([]);
    const [isDivVisible, setDivVisible] = useState(true);
    const [Totale, setTotale] = useState<number>(0);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const storedProducts = localStorage.getItem('productVisiteur');
        if (storedProducts) {
            try {
                setProducts(JSON.parse(storedProducts));
            } catch (error) {
                console.error('Error parsing products from local storage', error);
            }
        }
    }, []);

    const handleDeleteProduct = (id: string) => {
        deleteProduct(id, setProducts);
    };

    const handleVidePanier = () => {
        localStorage.removeItem('StartDate');
        localStorage.removeItem('productVisiteur');
        setDivVisible(false);
        setProducts([]);
        setMessage('Panier vidé avec succès!');
        setTimeout(() => {
            setMessage('');
        }, 3000);
    };

    const sumQty = () => {
        let total = 0;
        for (let i = 0; i < products.length; i++) {
            const QtyXprice = Number(products[i].price) * Number(products[i].quantity);
            total += QtyXprice;
        }
        setTotale(total);
    };

    const productVisiteur = localStorage.getItem('productVisiteur');

    useEffect(() => {
        if (productVisiteur && productVisiteur.length === 0) {
            localStorage.removeItem('StartDate');

        }
    }, [productVisiteur]);

    useEffect(() => {
        sumQty();
    }, [products]);

    return (
        <>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
            <div className="visiteur">
                <PanierChrono />
                <div className="title-app-page">MERCHSENTRY<br />
                    <svg className="underline-title" width="149" height="5" viewBox="0 0 149 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.91077 0.821324C0.919613 0.821324 0.116124 1.62481 0.116124 2.61597C0.116124 3.60712 0.919613 4.41061 1.91077 4.41061V0.821324ZM146.679 4.41061H148.473V0.821324H146.679V4.41061ZM1.91077 4.41061H146.679V0.821324H1.91077V4.41061Z" fill="#D4B996" />
                    </svg>
                </div>

                <i onClick={handleGoBack} style={{ marginLeft: "30px" }} className="arrow bi bi-arrow-left"></i>
                <div className="container-panier">
                    <div className="produits">
                        <h5 className='mx-3 mt-3'>Votre panier({products.length}) </h5>
                        {isDivVisible ? (
                            products.map((item, index) => (
                                <div key={index} className='produits-panier'>
                                    <div className='image-produit Itemimg'>
                                        <img src={item.imageUrl} alt="" /><br />
                                    </div>
                                    <div className='info-produit'>
                                        <h5 className='nom-produit'><b>{item.name}</b></h5><br />
                                        <h5><b>Prix de produit:</b> {item.price} MAD</h5> <br />
                                        <h5><b>Référence:</b> {item.id}</h5>
                                    </div>
                                    <div className='quantite-produit'>
                                        <h4>Quantité:  </h4> <br />
                                        <input readOnly={true} type='number' value={item.quantity} className="form-control" min={1} />
                                    </div>
                                    <div className='prix-produit'>
                                        <h5> {Number(item.price) * Number(item.quantity)} MAD</h5>
                                    </div>
                                    <div onClick={() => handleDeleteProduct(item.id)} >
                                        <button className='btn text-danger'> <i className="bi bi-trash"></i> supprimer </button>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div>{message}</div>
                        )}
                    </div>

                    <div className='somme'>
                        <h5 style={{ textAlign: 'center' }}> Total</h5><br />
                        <p><strong>Subtotal:</strong></p>  <span>{Totale} MAD</span>
                        <hr />
                        <p><strong>Discount:</strong> </p> <span>0 MAD</span>
                        <hr />
                        <p><strong>Shipping:</strong></p>  <span>0 MAD</span>
                        <hr />
                        <p><strong>Total:</strong></p>  <span>{Totale} MAD</span> <br />

                        <button className="mt-5 valide-btn">Valide</button>
                        <button className="valide-btn" onClick={handleVidePanier}>Vider le panier</button>
                    </div>
                </div>
            </div>
    </>
);
}