import { Link } from "react-router-dom";
import { ArticleInfo } from "../../../Modeles/ArticleModel";
import "./allproduitvisiteur.css";
import { useEffect, useState } from "react";
import React from 'react';
import AfficheProductsService from "../../../Services/Admin/AfficheProductsService";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import imgProduit from "./cassette-8-vitesses-12x32 1(1).png";
import logoBrand from "./Logo_Decathlon_RVB 1.png";

export interface ProductType {
    product: ArticleInfo[];
    messageErrors: string;
}

export interface ArticleInfos {
    min: string;
    max: string;
    date: string;
    setDate: React.Dispatch<React.SetStateAction<string>>;
    setMin: React.Dispatch<React.SetStateAction<string>>;
    setMax: React.Dispatch<React.SetStateAction<string>>;
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
    handleSubmitDate: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
}

const AllProductVisiteur: React.FC<ArticleInfos> = ({
    min,
    max,
    date,
    setDate,
    setMin,
    setMax,
    handleSubmit,
    handleSubmitDate,
}) => {
    const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
    const [state, setState] = useState<ProductType>({
        product: [] as ArticleInfo[],
        messageErrors: "aucun produit"
    });
    const [filteredProducts, setFilteredProducts] = useState<ArticleInfo[]>([]);
    const [Message, setMessage] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 30;
    const [totalPages, setTotalPages] = useState(1);
    const [distances, setDistances] = useState<Map<number, number>>(new Map());
    const [userLocation, setUserLocation] = useState<{ latitude: number; longitude: number } | null>(null);

     // Map to store distances by product ID
     const filterByLocation = (product: ArticleInfo) => {
    if (!userLocation) {
        return { isMatch: true, distance: 0 }; // Default to a match if no location
    }

    const toRadians = (deg: number) => deg * (Math.PI / 180);
    const earthRadius = 6371; // Radius of Earth in kilometers

    const lat1 = toRadians(userLocation.latitude);
    const lon1 = toRadians(userLocation.longitude);
    const lat2 = toRadians(33.5793544); // Fixed latitude
    const lon2 = toRadians(-7.5714198); // Fixed longitude

    const dLat = lat2 - lat1;
    const dLon = lon2 - lon1;

    const a = Math.sin(dLat / 2) ** 2 +
        Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = earthRadius * c; // Distance in kilometers

    return { isMatch: true, distance }; // Return match status and calculated distance
};

useEffect(() => {
    setLoading(true);
    AfficheProductsService().getProduct()
        .then((res: any) => {
            const filtered = res.data.data.map((pro: ArticleInfo) => {
                const { isMatch, distance } = filterByLocation(pro);
                return { ...pro, isMatch, distance }; // Attach distance to each product
            }).filter((pro: ArticleInfo) => pro.isMatch);
            filtered.sort((a: { distance: number }, b: { distance: number }) => a.distance - b.distance);
            setFilteredProducts(filtered);
        })
        .catch((msg: any) => setMessage(msg.messageErrors));
    setLoading(false);

}, [userLocation]);


    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        if (selectedValue === '2') {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        setUserLocation({
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                        
                        });
                        setMessage("");
                    },
                    (error) => {
                        setMessage('Please enable your location.');
                    }
                );
            } else {
                setMessage('Geolocation is not supported by your browser.');
            }
        } else {
            setUserLocation(null);
            setMessage("");
        }
    };
    const handleChangePage = (pageNumber: number) => {
        const totalPages = Math.ceil(filteredProducts.length / recordsPerPage);
        if (pageNumber > 0 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value =setSearch(e.target.value.trim());
        console.log("Search value:", value);

    };

    // const filterByDate = (productDate: string) => {
    //     const today = new Date();
    //     const productDateObj = new Date(productDate);
    
    //     switch (date) {
    //         case "Ce jour":
    //             return productDateObj.toDateString() === today.toDateString();
    //         case "Cette semaine":
    //             const startOfWeek = new Date(today);
    //             startOfWeek.setDate(today.getDate() - today.getDay());
    //             const endOfWeek = new Date(startOfWeek);
    //             endOfWeek.setDate(startOfWeek.getDate() + 6);
    
    //             return productDateObj >= startOfWeek && productDateObj <= endOfWeek;
    //         case "Ce mois":
    //             const isSameMonth = productDateObj.getMonth() === today.getMonth();
    //             const isSameYear = productDateObj.getFullYear() === today.getFullYear();
    //             return isSameMonth && isSameYear;
    //         case "Cette année":
    //             return productDateObj.getFullYear() === today.getFullYear();
    //             case "2019":
    //                 return productDateObj.getFullYear() === 2019;
    //             default:
    //                 return true;
    //     }
    // };
    
    useEffect(() => {
    setLoading(true);
        setState((prevState) => ({ ...prevState, messageErrors: "aucun produit" }));
        AfficheProductsService().getProduct()
            .then((res: any) => {
                let filtered = res.data.alldata.filter((pro: ArticleInfo) => {
                    const searchTerm = search.toLowerCase();
                    const designation = pro.Designation.toLowerCase();
                    const designationAr = pro.DesignationAr?.toLowerCase();
                    const refArticle = pro.RefArticle.toString().toLowerCase();
                    const sub = pro.LibelleSubstitut?.toString().toLowerCase();
                    const minPrice = parseFloat(min) || 0;
                    const maxPrice = parseFloat(max) || Infinity;
                    const productPrice = parseFloat(pro.PrixVenteArticleHT);
                    const isSearchMatch =
                        searchTerm === "" ||
                        designation.includes(searchTerm) ||
                        designationAr?.includes(searchTerm) ||
                        refArticle.startsWith(searchTerm) ||
                        sub?.startsWith(searchTerm);

                        const isPriceMatch = productPrice >= minPrice && productPrice <= maxPrice;    
                        return isSearchMatch && isPriceMatch;
                });
                setFilteredProducts(filtered);
                setState((prevState: any) => ({ ...prevState, product: res.data }));
            })
            .catch((msg: any) =>
                setState((prevState: any) => ({
                    ...prevState,
                    messageErrors: msg.messageErrors,
                }))
            );
            setLoading(false);

    }, [search, min, max, date]);
    

    const { product, messageErrors } = state;
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = filteredProducts.slice(indexOfFirstRecord, indexOfLastRecord);
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredProducts.length / recordsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />

            <div className="visiteur">
                <Link to={"/visiteurs/panier"}>
                    <div className="panier-visiteur-page">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-cart3" viewBox="0 0 16 16">
                            <path style={{ color: "black" }} d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l.84 4.479 9.144-.459L13.89 4zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                        </svg>
                        <span className="num">1</span>
                    </div>
                </Link>

                <div className="title-app-page">MERCHSENTRY<br />
                    <svg className="underline-title" width="149" height="5" viewBox="0 0 149 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.91077 0.821324C0.919613 0.821324 0.116124 1.62481 0.116124 2.61597C0.116124 3.60712 0.919613 4.41061 1.91077 4.41061V0.821324ZM146.679 4.41061H148.473V0.821324H146.679V4.41061ZM1.91077 4.41061H146.679V0.821324H1.91077V4.41061Z" fill="#D4B996" />
                    </svg>
                    
                </div>
                <div className="container_div mt-5 barreRecherche-page" style={{ width: '100vw' }}>
                    <div className="" style={{ display: 'flex', marginLeft: '-3%', alignItems: "center" }}>
                        <div className="" style={{ width: '65vw' }}>
                            <div className="position-relative">
                                <i className="fa fa-search fa-2x position-absolute" style={{ color: "#C5C5C5", top: '50%', fontSize: "1.4rem", transform: 'translateY(-50%)', left: '10px' }} />
                                <input type="text" onChange={handleSearch} className="form-control form-input pl-4" placeholder="Recherch un produit, ref .." />
                                <i className="bi bi-sliders fa-2x position-absolute" style={{ fontSize: "1.4rem", color: "#C5C5C5", top: '50%', transform: 'translateY(-50%)', right: '10px' }} />
                            </div>
                        </div>
                    </div>
                </div>

                <br />

                <div className="liste-page" style={{ display: 'flex', gap: '70px' }}>

                    <form onSubmit={handleSubmit} className="input-group mb-3" style={{ width: '200px', marginLeft: '70px' }}>
                        <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" style={{ background: '#FFC400', color: '#000' }}> Prix </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">

                            <li className="liste-deroulante">
                                <div className="input-group" style={{ width: '17vw', marginTop: '20px', marginRight: '10px' }}>
                                    Min &nbsp;&nbsp;&nbsp;
                                    <input type="text" onChange={(e) => setMin(e.target.value)} className="form-control" style={{ marginTop: '-5px' }} />
                                </div>
                            </li>

                            <li className="liste-deroulante">
                                <div className="input-group" style={{ width: '17vw', marginTop: '20px', marginRight: '10px' }}>
                                    Max &nbsp;&nbsp;&nbsp;
                                    <input onChange={(e) => setMax(e.target.value)} type="text" className="form-control" style={{ marginTop: '-5px' }} />
                                </div>
                                {/* <div>
                                    <button className="envoye" type="submit" style={{ marginLeft: '90px', color: 'black' }}>Applique</button>
                                </div> */}
                            </li>
                        </ul>
                    </form>

                    <div className="input-group mb-3" style={{ width: '50vw' }}>
                        <label className="input-group-text" htmlFor="inputGroupSelect01" style={{ background: '#FFC400', color: '#000' }}>Distance</label>
                        <select className="form-select" id="inputGroupSelect01" onChange={handleChange}>
                        <option value="1"> <input type="text" name="text" id="text" /> </option>
                        <option value="2"> <i className="bi bi-geo-alt"></i> Activez votre localisation </option>
                    </select>
                    </div>

                    <div className="input-group mb-3" style={{ width: '50vw' }}>
                        <label className="input-group-text" htmlFor="inputGroupSelect01" style={{ background: '#FFC400', color: '#000' }}>Date</label>
                        <select className="form-select" id="inputGroupSelect01">
                            <option value="1"> <input type="text" name="text" id="text" /> </option>
                            <option value="Ce jour">Ce jour</option>
                            <option value="Cette semaine">Cette semaine</option>
                            <option value="Ce mois">Ce mois</option>
                            <option value="Cette année">Cette année</option>*
                            <option value="2019">2019</option>

                        </select>
                    </div>
                </div>
                <br />
               
                {loading===true?(
          <div className="d-flex justify-content-center align-items-center" style={{ height: '250px', color: "#FFC400" }}>
          <div className="spinner-border custom-spinner" role="status">
          </div>
            <span className="loader-charg"> Chargement..</span>
        </div>
        ):(
          <>
          <div className="row mt-5">
{
    currentRecords.length > 0 ? currentRecords.map(pro => (
        <>
        <div className="col-lg-3 col-md-6 col-12 mb-3" >
<Link to ={`/visiteurs/articles/${pro.RefArticle[0]}`}>

            <div className="card_slid">
<img style={{width:"90%" , paddingLeft:"8%"}} src={pro.urlImage}
alt={pro.alt || 'Image'} />
<div className="box-visiteur">
<p>
  {pro.distance !== undefined && pro.distance !== null 
    ? `${pro.distance.toFixed(2)} km de chez vous`
    : "+100 km de chez vous"}
</p>
</div>
<div className="name-produit-visiteur">
<p>{pro.Designation}{pro.DesignationAr ? ` | ${pro.DesignationAr}` : ""}
</p>
<h5>{pro.PrixVenteArticleHT} MAD</h5>
<div className="brand">
                                        <img style={{ height: '30px' }} src={logoBrand} alt="" />
                                        <svg className="panier-box-visiteur" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        </svg>
                                    </div>
</div>
</div>

</Link>
        </div>
        </>

    )) : (
        <div className="no-produit">
              <i className="bi bi-emoji-neutral"></i><br />
              <p>Malheureusement, aucun produit trouvé pour l'instant.</p><br />
            </div>
    )
}

        </div>
        
        <div className="pagination mb-5">
              <button
                className="page-button"
                onClick={() => handleChangePage(currentPage - 1)}
                disabled={currentPage === 1}
              >
                précedent
              </button>
              <span className="page-info">Page {currentPage} of {Math.ceil(filteredProducts.length / recordsPerPage)}</span>
              <button
                className="page-button"
                onClick={() => handleChangePage(currentPage + 1)}
                disabled={currentPage * recordsPerPage >= filteredProducts.length}
              >
                Suivant
              </button>
        </div>
          </>
        )
        }
            </div>
        </>
    );
}

export default AllProductVisiteur;
