import SideBareMagasin from "../SideBareMagasin/SideBareMagasin";
import { Link } from "react-router-dom";
import { ArticleInfo } from "../../../Modeles/ArticleModel";
import { useContext, useEffect, useState } from "react";
import AfficheArticleInMagasin from "../../../Services/Magasin/AfficheArticleInMagasin";
import { MagasinContext } from "../../../Context/MagasinContext";
import "./ProduitMagasin.css";
import AfficheProductsService from "../../../Services/Admin/AfficheProductsService";

export interface ProductType {
  product: ArticleInfo[],
  messageErrors: string,
}

export default function ProduitMagasin() {
  const magasinContext = useContext(MagasinContext);
  const MagasinId = localStorage.getItem('MagasinId');
  const id = MagasinId || magasinContext.id?.id;

  const [search, setSearch] = useState("");
  const [state, setState] = useState<ProductType>({
    product: [] as ArticleInfo[],
    messageErrors: "aucun produit",
  });
  const [products, setProducts] = useState<ArticleInfo[]>([]);
  const [alldata, setAlldata] = useState<ArticleInfo[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 30;
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const fetchProducts = (pageNumber: number) => {
    setLoading(true);
    AfficheProductsService().getProductPagination(pageNumber)
      .then((res: any) => {
        setProducts(res.data.data);
        setAlldata(res.data.alldata); // Save all data for searching
        setTotalPages(res.data.last_page);
        setLoading(false);
      })
      .catch((err: any) => {
        console.error("Error fetching products: ", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchProducts(currentPage);
  }, [currentPage]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(1); // Reset to page 1 when search is performed
  };

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  // Filter products based on search term using alldata
  const filteredProducts = alldata.filter((pro: ArticleInfo) => {
    // Ensure RefArticle is an array
    const refs = Array.isArray(pro.RefArticle) ? pro.RefArticle : [pro.RefArticle];
  
    return (
      pro.Designation?.toLowerCase().includes(search.toLowerCase()) ||
      pro.PrixVenteArticleTTC?.toLowerCase().includes(search.toLowerCase()) ||
      refs.some(ref => ref?.toLowerCase().includes(search.toLowerCase())) || // Check all references
      pro.DesignationAr?.toLowerCase().includes(search.toLowerCase())
    );
  });
  const paginatedProducts = filteredProducts.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage);

  const renderProducts = () => {
    if (paginatedProducts && paginatedProducts.length > 0) {
      return paginatedProducts.map((pro) => (
        <div className="itemPro" key={pro.IdArticle}>
          <div className="card" id="allItems">
            <div className="iconPoint">
              <i className="bi bi-three-dots-vertical"></i>
            </div>
            <Link className="txN" to={`/magasins/${id}/articles/${pro.RefArticle[0]}`}>
              <div className="contentMagasinP">
                {/* {pro.Remise && pro.Remise > 0 && (
                  <span className="reduction-product">{pro.Remise}% de réduction</span>
                )} */}
                <div className="Itemimg">
                  <img src={pro.urlImage} alt={pro.Designation} /><br />
                  <p>{pro.PrixVenteArticleHT} MAD<span className="pu"> / p.u</span></p>
                </div>
                <div className="infoPM">
                  <p>{pro.Designation}{pro.DesignationAr ? ` | ${pro.DesignationAr}` : ""}</p><br />
                  <span>+1000 ventes</span>
                </div>
              </div>
            </Link>
          </div>
        </div>
      ));
    } else {
      return (
        <div className="no-produit">
          <i className="bi bi-emoji-neutral"></i>
          <br />
          <p>
            Malheureusement, on n‘a pas ce produit pour l’instant.
          </p>
          <br />
          <Link
            to={"https://api.whatsapp.com/send?phone=212661718081"}
            target="_blank"
            className="botton-remplir"
          >
            <button>
              <i className="bi bi-whatsapp"></i>Contactez-nous
            </button>
          </Link>
        </div>
      );
    }
  };
  

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredProducts.length / recordsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <SideBareMagasin />

      <div className="container mt-5">
        <div className="row height">
          <div className="col-md-6">
            <div className="form">
              <i className="fa fa-search" />
              <input type="text" onChange={handleSearch} className="form-control form-reherch form-input" placeholder="Rechercher un produit, ref .." />
              {/* <span className="left-pan"><i className="bi bi-sliders"></i></span> */}
              <Link to={`/magasins/${id}/articles/add`}><button className="btnAjouteM">Ajouter un produit</button></Link>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
      {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '250px', color: "#FFC400" }}>
            <div className="spinner-border custom-spinner" role="status">
            </div>
              <span className="loader-charg"> Chargement ..</span>
          </div>
        ) : (
          <>
            <div className="row">
              {renderProducts()}
            </div>
            <div style={{marginLeft:"15px"}} className="pagination mb-5">
              <button
                className="page-button"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                précedent
              </button>
              <span className="page-info">Page {currentPage} of {Math.ceil(filteredProducts.length / recordsPerPage)}</span>
              <button
                className="page-button"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage * recordsPerPage >= filteredProducts.length}
              >
                Suivant
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
