import { Link } from "react-router-dom";
import "./historique.css"
import SideBareMagasin from "../SideBareMagasin/SideBareMagasin";
import { useContext, useEffect, useState } from "react";
import { MagasinContext } from "../../../Context/MagasinContext";
import { Commande } from "../../../Modeles/Commande";
import HistoriqueMagasinService from "../../../Services/Magasin/HistoriqueMagasinService";
export interface commandeType{

  product : Commande[],
  messageErros:string,
}
export default function HistoriqueMagasin(){
  const itemsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);
  const [statusFilter, setStatusFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const magasinContext = useContext(MagasinContext);
  const MagasinId = localStorage.getItem('MagasinId');
    const id = MagasinId || magasinContext.id?.id;
    const [state , setState] = useState<commandeType>({
      product:[] as Commande[],
      messageErros : "accune commande",
  
  })
  const [stateMagasin , setStateMagasin] = useState<commandeType>({
    
    product:[] as Commande[],
    messageErros : "accune produit",
  
  })
  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStatusFilter(e.target.value);
  };
  const handleChangePage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };
  useEffect(()=>{
      setState({...state })
        HistoriqueMagasinService().getCommande()
          .then((res)=>setState({...state  , product:res.data})
  
          )
          .catch(msg=>setState({...state  , product:msg.messageErros}))
  },[]);
  const {product , messageErros} = state
  const filteredProducts = product.filter((p) =>
    p.IdCommande?.toString().toLowerCase().includes(searchTerm.toLowerCase()) &&
    (statusFilter === "" || p.Statut === statusFilter)
  );

  // Pagination calculation
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredProducts.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
    return<>
    <SideBareMagasin/>
    <div className="container mt-5">

    <h4>Historique des commandes</h4>
    <div className="filtreElement mb-5">
    <Link to="" className="btnFilterS"><a>Cette semaine</a></Link>
    <Link to="" className="btnFilterM"><a >Ce mois </a></Link> 
    </div>
    {/* <div className="tableInfo ">
  <table className="table table-bordered ">
    <thead>
      <tr>
        <th scope="col" className="ncom">N de commande</th>
        <th scope="col">Date</th>
        <th scope="col">Montant</th>
        <th scope="col">details</th>
        <th scope="col">Statut</th>
      </tr>
    </thead>
    <tbody>
      { product &&product.length>0?product.map(pro=>(
      <tr>
        <td>
      <Link className="columnTable" to={`/magasins/${id}/historiques/id`}>
        {pro.IdCommande}
      </Link>
      </td>
        <td>{pro.DateCommande}</td>
        <td>{pro.TotalCommandeHT} MAD</td>
        <td style={{ color: pro.Statut === 'confirme' ? 'green' : pro.Statut === 'annuler' ? 'red' : 'black' }}>{pro.Statut}</td>
        <td><Link className="vdbtn" to={`/magasins/${MagasinId}/historiques/${pro.IdCommande}`}>Voir detail <i className ="bi bi-eye-fill"></i></Link></td>
      </tr>
      )):<h5 className="mt-5 text-center">Pas de commande </h5>}

      
    </tbody>
  </table>
<div className="mb-5 b-p-u-c">

<Link to={`/magasins/${id}/commande`}className="p-u-c">Passez une commande</Link>
</div>
</div> */}
<div className="table-container mt-5">
          <div className="table-controls">
            <div className="search-wrapper">
              <input
                type="text"
                placeholder="Search by N commande..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <span className="search-icon">
                <i className="bi bi-search"></i>
              </span>
            </div>
            <select className="select-etat" onChange={handleStatusChange} value={statusFilter}>
              <option value="">Tous</option>
              <option value="confirme">Confirme</option>
              <option value="en cours..">En cours</option>
              <option value="annuler">Annuler</option>
            </select>
          </div>

          <table>
            <thead>
              <tr>
              <th scope="col" className="ncom">N de commande</th>
              <th scope="col">Date</th>
              <th scope="col">Montant</th>
              <th scope="col">details</th>
              <th scope="col">Statut</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.length > 0 ? (
                currentItems.map((item, index) => (
                  <tr key={index}>
                    <td>{item.IdCommande}</td>
                    <td>{item.DateCommande}</td>
                    <td>{item.TotalCommandeHT} MAD</td>
                    <td><Link className="vdbtn" to={`/magasins/${MagasinId}/historiques/${item.IdCommande}`}>Voir detail <i className ="bi bi-eye-fill"></i></Link></td>
                    <td><span className="s-tab-h" style={{
    color: item.Statut === 'confirme' ? '#1fa750' : item.Statut === 'annuler' ? '#df2222' : "#cfa00c",
    backgroundColor: item.Statut === 'confirme' ? '#cff6dd' : item.Statut === 'annuler' ? '#ffc9c9' : "#f3e9c8"
}}><b style={{fontSize:"3rem"}}> .</b> {item.Statut}</span></td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={8}>{messageErros}</td>
                </tr>
              )}
            </tbody>
          </table>

          <div className="pagination mb-5">
            <button
              onClick={() => handleChangePage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Précédente
            </button>

            {pageNumbers.map((number) => (
              <button
                key={number}
                onClick={() => handleChangePage(number)}
                className={currentPage === number ? "active" : ""}
              >
                {number}
              </button>
            ))}

            <button
              onClick={() => handleChangePage(currentPage + 1)}
              disabled={currentPage === pageNumbers.length}
            >
              Suivant
            </button>
          </div>
        </div>
        <div className="mb-5 b-p-u-c">

<Link to={`/magasins/${id}/commande`}className="p-u-c">Passez une commande</Link>
</div>
</div>

        
    </>
}