import { SetStateAction, useContext, useEffect, useState } from "react";
import { MagasinContext } from "../../Context/MagasinContext";
import axios, { AxiosError } from "axios";
import { useParams } from "react-router-dom";
import AfficheProduitMagasin from "../../Views/Magasin/HomeMagasin/AfficheProduitsMagasin";

export default function AfficheArticleById(){
    const [Designation,setDesignation] = useState<string>("");
    const [IdArticle,setIdArticle] = useState<number>(0);
    const [PrixVenteArticleTTC,setPrixVenteArticleTTC] = useState<string>("");
    const [RefArticle,setRefArticle] = useState<string>("");
    const [urlImage,setimage] = useState<any>("");
    const [Description,setDescription] = useState<string>("");
    const [quantité,setQuantité] = useState<number>(0);
    const [LibelleSubstitut,setLibelle] = useState<string>("")
    const [prix2,setPrix2] = useState<string>("")
    const [prix3,setPrix3] = useState<string>("")
    const [prix1,setPrix1] = useState<string>("")
    const [Remise,setRemise] = useState<string>("")
    const [prixTtc,setPrixTtc] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(true);


    const MagasinId = localStorage.getItem('MagasinId');  
    const { ref } = useParams<{ ref?: string; pr?: any }>(); 
    useEffect(()=>{
        fetchProduct();
    },[])
    const fetchProduct=async()=>{
      const url =`${process.env.REACT_APP_PHP_APP_URL}/articles/${ref}` ;
        try {
          await axios.get(url)
          .then(({data})=>{
              setRemise(data.Remise);
              setDesignation(data.Designation);
              setIdArticle(data.IdArticle);
              setimage(data.urlImage);
              setPrixVenteArticleTTC(data.PrixNetHT);
              setRefArticle(data.RefArticle);
              setQuantité(data.QteQtock); 
                setLoading(false)
                setLibelle(data.LibelleSubstitut)

        })} catch (error: AxiosError | any) {
          if (error.response?.status === 442) {
          console.log("good data");
          setLoading(false)

    
          } else {
            console.log("error");
          }
        }
          
      }
      return<>
<AfficheProduitMagasin
prixTtc={prixTtc}
          IdArticle={IdArticle}
          Remise={Remise}
          Designation={Designation}
          PrixVenteArticleTTC={PrixVenteArticleTTC}
          LibelleSubstitut={LibelleSubstitut}
          RefArticle={RefArticle}
          urlImage={urlImage}
          Description={Description}
          quantité={quantité}
          prix2={prix2}
          prix_ht_3_magasin={prix3}
          prix_ht_1_magasin={prix1}
          loading={loading}
          Unite={""} setDesignation={function (value: SetStateAction<string>): void {
            throw new Error("Function not implemented.");
          } } setPrixVenteArticleTTC={function (value: SetStateAction<string>): void {
            throw new Error("Function not implemented.");
          } } setDescription={function (value: SetStateAction<string>): void {
            throw new Error("Function not implemented.");
          } } setImage={function (value: SetStateAction<File | null>): void {
            throw new Error("Function not implemented.");
          } } setstock={function (value: SetStateAction<string>): void {
            throw new Error("Function not implemented.");
          } } setRefARticle={function (value: SetStateAction<string>): void {
            throw new Error("Function not implemented.");
          } } setUnite={function (value: SetStateAction<string>): void {
            throw new Error("Function not implemented.");
          } } setQuantité={function (value: SetStateAction<string>): void {
            throw new Error("Function not implemented.");
          } } setprix_ht_2_magasin={function (value: SetStateAction<string>): void {
            throw new Error("Function not implemented.");
          } } setprix_ht_3_magasin={function (value: SetStateAction<string>): void {
            throw new Error("Function not implemented.");
          } } setprix_ht_1_magasin={function (value: SetStateAction<string>): void {
            throw new Error("Function not implemented.");
          } } setquantité={function (value: SetStateAction<string>): void {
            throw new Error("Function not implemented.");
          } } setNomClient={function (value: SetStateAction<string>): void {
            throw new Error("Function not implemented.");
          } } setAdresse={function (value: SetStateAction<string>): void {
            throw new Error("Function not implemented.");
          } } setTotalCommandeHT={function (value: SetStateAction<string>): void {
            throw new Error("Function not implemented.");
          } } setTotalRemise={function (value: SetStateAction<string>): void {
            throw new Error("Function not implemented.");
          } }        />
</>
}