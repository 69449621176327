import { Link } from "react-router-dom";
import "./HomeCommercial.css";
import logovisa from "../../Utilisateure/client/logo visa.png";
import logosimvisa from "../../Utilisateure/client/logo plusVisa.png";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css/free-mode';
import 'swiper/css/autoplay';
import { Autoplay, FreeMode } from 'swiper/modules';  
import { useContext, useEffect, useState } from "react";
import { ArticleInfo } from "../../../Modeles/ArticleModel";
import AfficheArticleInCommercial from "../../../Services/Magasin/AfficheArticleInCommercial";
import SideBareCommercial from "../SideBareCommercial/SideBareCommercial";
import ImageProduit3 from "../../Admin/IMG/3.png";
import { MagasinContext } from "../../../Context/MagasinContext";

export interface ProductType {
  product: ArticleInfo[];
  messageErros: string;
}

export default function HomeCommercial() {
  const ClientId = localStorage.getItem("ClientId");
  const magasinContext = useContext(MagasinContext);
  const MagasinId = localStorage.getItem("CommercialId");
  const CommercialName = localStorage.getItem("CommercialName");
  const id = MagasinId || magasinContext.id?.id;
  const nameMagasin = CommercialName || magasinContext.id?.nom;
  const [search, setSearch] = useState("");
  const [state, setState] = useState<ProductType>({
    product: [] as ArticleInfo[],
    messageErros: "accune produit",
  });

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value.trim()); // Trim the search term
  };

  useEffect(() => {
    AfficheArticleInCommercial()
      .getArticle()
      .then((res) => setState({ ...state, product: res.data }))
      .catch((msg) => setState({ ...state, product: msg.messageErros }));
  }, []);

  const { product, messageErros } = state;
  return (
    <>
      <SideBareCommercial />
      <div className="container">
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
        <div className="categ">
          <h5 className="mt-5">
            <b id="h5Hc">Bienvenue,</b> choisis les produits que vous êtes le
            plus l'aise de vendre !
          </h5>
          <div className="row">
            <div className="col-9 col-70">
              <div className=" mt-5">
                <div className="form forme">
                  <i className="fa fa-search" />
                  <input
                    type="text"
                    onChange={handleSearch}
                    className="form-control form-input"
                    placeholder="Recherchez un produit, ref .."
                  />
                  <span className="left-pan">
                    <i className="bi bi-sliders"></i>
                  </span>
                </div>
              </div>
              <div className="equipement"></div>
            </div>

            <div className="col-3 col-30">
            <div className="card-cart-solde">
	<div className="card__info">
		<div className="card__chip">
			<svg className="card__chip-lines" role="img" width="20px" height="20px" viewBox="0 0 100 100" aria-label="Chip">
				<g opacity="0.8">
					<polyline points="0,50 35,50" fill="none" stroke="#000" stroke-width="2" />
					<polyline points="0,20 20,20 35,35" fill="none" stroke="#000" stroke-width="2" />
					<polyline points="50,0 50,35" fill="none" stroke="#000" stroke-width="2" />
					<polyline points="65,35 80,20 100,20" fill="none" stroke="#000" stroke-width="2" />
					<polyline points="100,50 65,50" fill="none" stroke="#000" stroke-width="2" />
					<polyline points="35,35 65,35 65,65 35,65 35,35" fill="none" stroke="#000" stroke-width="2" />
					<polyline points="0,80 20,80 35,65" fill="none" stroke="#000" stroke-width="2" />
					<polyline points="50,100 50,65" fill="none" stroke="#000" stroke-width="2" />
					<polyline points="65,65 80,80 100,80" fill="none" stroke="#000" stroke-width="2" />
				</g>
			</svg>
		<div className="card__name" aria-label="Dee Stroyer">Nom Client</div>

			<div className="card__chip-texture"></div>
		</div>
		<div className="card__number">
			<span className="card__digit-group">0000</span>
			<span className="card__digit-group">0021</span>
			<span className="card__digit-group">4748</span>
			<span className="card__digit-group">3647</span>
		</div>
		
		<div className="card__valid-thru" aria-label="Valid thru">Cart <br/>Fidélité</div>
		<div className="card__exp-date"><time dateTime="2038-01">01/26</time></div>
		<div className="card__name" aria-label="Dee Stroyer">Nom Client</div>
	</div>
	
	<div className="card__texture"></div>
</div>
              <div className="progresse">
                <div
                  className="progress"
                  style={{ width: "55%", backgroundColor: "#FFC400" }}
                ></div>
                <div className="solde">
                  <h6>solde:</h6>
                  <span> 5 380 MAD / 10 000 MAD</span>
                </div>
              </div>
            </div>
          </div>

          <div className="equipement mt-3">
            <div className="bar-title-client">
              <h4>Equipements</h4>
              <Link
                to={`/commercials/${id}/articles`}
                className="vp-client"
              >
                Voir plus <i className="bi bi-arrow-right"></i>
              </Link>
              <div className="pod"></div>
            </div>
          </div>

          <Swiper
      freeMode={true}
      grabCursor={true}
      className="container myswiper"
      breakpoints={{
        0: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 80,
        },
        600: {
          slidesPerView: 3,
          spaceBetween: 80,
        },
        800: {
          slidesPerView: 4,
          spaceBetween: 2,
        },
      }}
      autoplay={{
        delay: 2000, 
        disableOnInteraction: false,
      }}
      modules={[Autoplay, FreeMode]}
    >
            {product && product.length > 0 ? (
              product
                .filter((pro) => {
                  const searchTerm = search.trim().toLowerCase();
                  const designation = pro.Designation.toLowerCase();
                  const refArticle = pro.RefArticle.toString().toLowerCase();
                  const sub = pro.LibelleSubstitut?.toString().toLowerCase();

                  const searchTerms = searchTerm.split(" ");

                  return searchTerms.every(
                    (term) =>
                      designation.includes(term) ||
                      refArticle.startsWith(term) ||
                      (sub && sub.startsWith(term))
                  );
                })
                .map((pro) => (
                  <SwiperSlide className="upBox" key={pro.IdArticle}>
                                  <Link className="upboxclient" to={`/commercials/${ClientId}/articles/${pro.IdArticle}`}>

                      <div className="box">
                      {
                        pro.réduction && pro.réduction > 0 ? (
                          <span className="reduction">{pro.réduction}% de réduction</span>
                        ) : (
                          ""
                        )
                      }
                        <div className="slidImage">
                          <img src={ImageProduit3} alt="" />
                          <div className="overlay"></div>
                        </div>
                        <div className="detailBoxMagasin">
                          <div className="type">
                            <p className="paran">{pro.Designation}</p>
                            <i className="bi bi-cart-plus"></i>
                          </div>
                          <div className="price">
                            <p>
                              {pro.PrixVenteArticleTTC} MAD<span>/P.U</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))
            ) : (
              <div className="no-produit">
                <i className="bi bi-emoji-neutral"></i>
                <br />
                <p>
                  Malheureusement, on n‘a pas ce produit pour l’instant.
                </p>
                <br />
                <Link
                  to={"https://api.whatsapp.com/send?phone=212661718081"}
                  target="_blank"
                  className="botton-remplir"
                >
                  <button>
                    <i className="bi bi-whatsapp"></i>Contactez-nous
                  </button>
                </Link>
              </div>
            )}
          </Swiper>
        </div>
        <div className="row container mx-auto mt-5">
          {product && product.length > 0 ? (
            product
              .filter((pro) => {
                const searchTerm = search.toLowerCase();
                const designation = pro.Designation.toLowerCase();
                const refArticle = pro.RefArticle.toString().toLowerCase();
                const sub = pro.LibelleSubstitut?.toString().toLowerCase();
                return (
                  searchTerm === "" ||
                  designation.includes(searchTerm) ||
                  refArticle.startsWith(searchTerm) ||
                  sub?.startsWith(searchTerm)
                );
              })
              .map((pro) => (
                <div className="col-lg-4 col-md-3 col-12" key={pro.IdArticle}>
                    <Link className="upboxclient" to={`/clients/${ClientId}/articles/${pro.IdArticle}`}>

                    <div className="box">
                      <div className="slidImage">
                      {
                        pro.réduction && pro.réduction > 0 ? (
                          <span className="reduction">{pro.réduction}% de réduction</span>
                        ) : (
                          ""
                        )
                      }
                        <img src={ImageProduit3} alt="" />
                        <div className="overlay"></div>
                      </div>
                      <div className="detailBoxMagasin">
                        <div className="type">
                          <p className="paran">{pro.Designation}</p>
                          <i className="bi bi-cart-plus"></i>
                        </div>
                        <div className="price">
                          <p>
                            {pro.PrixVenteArticleTTC} MAD<span>/P.U</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
          ) : (
            <div className="no-produit">
              <i className="bi bi-info-lg"></i> Aucun produit
            </div>
          )}
        </div>
      </div>
    </>
  );
}
