import React, { useEffect, useState } from 'react';
import axios from 'axios';

interface Article {
  IdArticle: number; // Assuming IdArticle is a number
  RefArticle: string;
  Designation: string;
  LibelleFamArticle: string;
  NomMarque: string;
  PrixVenteArticleHT: number; // Assuming PrixVenteArticleHT is a number (float)
}

const ArticleList: React.FC = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Fetch articles from backend
    axios
      .get('http://127.0.0.1:8000/api/testapi') // Replace with your actual API endpoint
      .then((response) => {
        setArticles(response.data.articles);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading articles...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h1>List of Articles</h1>
      <h1></h1>
      <ul>
        {articles.map((article) => (
          <li key={article.IdArticle}>
            {article.Designation} ({article.RefArticle}) - {article.NomMarque} - ${article.PrixVenteArticleHT.toFixed(2)}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ArticleList;
