import React from 'react';
import './ContactNous.css'; // Assuming you have some styles defined

export default function ContactNous() {
    const handleGoBack = () => {
        window.history.back();
    };
    return (
        <>
                <div className="title-app">MERCHSENTRY<br />
            <svg className="underline-title" width="149" height="5" viewBox="0 0 149 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.91077 0.821324C0.919613 0.821324 0.116124 1.62481 0.116124 2.61597C0.116124 3.60712 0.919613 4.41061 1.91077 4.41061V0.821324ZM146.679 4.41061H148.473V0.821324H146.679V4.41061ZM1.91077 4.41061H146.679V0.821324H1.91077V4.41061Z" fill="#D4B996" />
            </svg>
            
        <span className="contact-icon"> Contactez nous <i className="bi bi-chat-square-text-fill"></i></span>
        </div>

            <div className="container-all">
        <i onClick={handleGoBack} style={{ marginLeft: "30px" }} className="arrow bi bi-arrow-left"></i>
                <div className="row">
                    <h1>Contactez Nous</h1>
                </div>
                <div className="row">
                    <h4 style={{ textAlign: 'center' }}>Nous écoutons toutes vos demandes</h4>
                </div>
                <div className="row input-container">
                    <div className="col-xs-12">
                        <div className="styled-input wide">
                            <input type="text" required  style={{ width: '100%' ,backgroundColor: "#2d2d2d",padding:"30px"}}/>
                            <label>Nom complet</label>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="styled-input" >
                            <input type="email" required  style={{ width: '100%',backgroundColor: "#2d2d2d" ,padding:"30px"}}/>
                            <label>Adresse e-mail</label>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="styled-input" style={{ float: 'right' }}>
                            <input type="tel" required style={{ width: '100%',backgroundColor: "#2d2d2d" ,padding:"30px" }} />
                            <label>Numéro de téléphone</label>
                        </div>
                    </div>
                    <div className="col-xs-12">
                        <div className="styled-input wide">
    
    <textarea required style={{ width: '100%',backgroundColor: "#2d2d2d", padding:"30px"}}></textarea>
                            <label>Message</label>
                        </div>
                    </div>
                    <div className="col-xs-12">
                        <div className="btn-lrg submit-btn-contact">Envoyer</div>
                    </div>
                </div>
            </div>
        </>
    );
}
