import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import "swiper/css/free-mode"
// import "bootstrap/dist/css/bootstrap.min.css"
import "./Homevisiteur.css"
import brand1 from "../../Admin/IMG/Logo_Decathlon_RVB 1.png"
import brand2 from "../../Admin/IMG/Group.png"
import brand3 from "../../Admin/IMG/logo 1.png"
import brand4 from "../../Admin/IMG/s1.png"
import panier from "../../Admin/IMG/cart.fill.png"


import { Link } from "react-router-dom"
import imgProduit from "../../Admin/IMG/cassette-8-vitesses-12x32 1.png"
import imgProduit3 from "../../Admin/IMG/4.png"

import imgProduit2 from "../../Admin/IMG/x1-bike_10w50_packshot_1L_front_1000px-768x768 1.png"

import logoBrand from "../../Admin/IMG/Logo_Decathlon_RVB 1.png"
import { useEffect, useState } from "react"
import { ArticleInfo } from "../../../Modeles/ArticleModel"
import AfficheProductsService from "../../../Services/Admin/AfficheProductsService"
import CardContainer from "../../../components/CardContainer"

export interface ProductType {

    product: ArticleInfo[],
    messageErros: string,
}
export default function HomeVisiteur() {
    const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
    const [state, setState] = useState<ProductType>({
        product: [] as ArticleInfo[],
        messageErros: "aucun produit"
    });
    const [filteredProducts, setFilteredProducts] = useState<ArticleInfo[]>([]);
    const [Message, setMessage] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 30;
    const [totalPages, setTotalPages] = useState(1);
    const [distances, setDistances] = useState<Map<number, number>>(new Map());
    const [userLocation, setUserLocation] = useState<{ latitude: number; longitude: number } | null>(null);

     // Map to store distances by product ID
     const filterByLocation = (product: ArticleInfo) => {
    if (!userLocation) {
        return { isMatch: true, distance: 0 }; // Default to a match if no location
    }

    const toRadians = (deg: number) => deg * (Math.PI / 180);
    const earthRadius = 6371; // Radius of Earth in kilometers

    const lat1 = toRadians(userLocation.latitude);
    const lon1 = toRadians(userLocation.longitude);
    const lat2 = toRadians(33.5793544); // Fixed latitude
    const lon2 = toRadians(-7.5714198); // Fixed longitude

    const dLat = lat2 - lat1;
    const dLon = lon2 - lon1;

    const a = Math.sin(dLat / 2) ** 2 +
        Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = earthRadius * c; // Distance in kilometers

    return { isMatch: true, distance }; // Return match status and calculated distance
};
const handlePageChange = (page: number) => {
  setCurrentPage(page);
};

useEffect(() => {
  setLoading(true);
  AfficheProductsService().getProduct()
    .then((res: any) => {
      const searchTerm = search.toLowerCase();
      const filtered = res.data.alldata
        .filter((pro: ArticleInfo) => {
          const designation = pro.Designation.toLowerCase();
          const designationAr = pro.DesignationAr?.toLowerCase();
          const refArticle = pro.RefArticle.toString().toLowerCase();
          const sub = pro.LibelleSubstitut?.toString().toLowerCase();
          
          // Match based on search term and article fields
          const isSearchMatch =
            searchTerm === "" ||
            designation.includes(searchTerm) ||
            designationAr?.includes(searchTerm) ||
            refArticle.startsWith(searchTerm) ||
            sub?.startsWith(searchTerm);

          if (!isSearchMatch) return false;

          // Check location filter if applicable
          const { isMatch } = filterByLocation(pro);
          return isMatch;
        })
        .map((pro: ArticleInfo) => {
          const { distance } = filterByLocation(pro);
          return { ...pro, distance };
        });

      // Sort the filtered products by distance
      filtered.sort((a: { distance: number }, b: { distance: number }) => a.distance - b.distance);

      // Pagination logic
      const paginatedProducts = filtered.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage);

      setFilteredProducts(paginatedProducts);
    })
    .catch((err: any) => setMessage(err.messageErrors))
    .finally(() => setLoading(false));
}, [userLocation, search, currentPage]);


    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        if (selectedValue === '2') {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        setUserLocation({
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                        
                        });
                        setMessage("");
                    },
                    (error) => {
                        setMessage('Please enable your location.');
                    }
                );
            } else {
                setMessage('Geolocation is not supported by your browser.');
            }
        } else {
            setUserLocation(null);
            setMessage("");
        }
    };
    const handleChangePage = (pageNumber: number) => {
        const totalPages = Math.ceil(filteredProducts.length / recordsPerPage);
        if (pageNumber > 0 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value =setSearch(e.target.value.trim());
        console.log("Search value:", value);

    };
    

    // const filterByDate = (productDate: string) => {
    //     const today = new Date();
    //     const productDateObj = new Date(productDate);
    
    //     switch (date) {
    //         case "Ce jour":
    //             return productDateObj.toDateString() === today.toDateString();
    //         case "Cette semaine":
    //             const startOfWeek = new Date(today);
    //             startOfWeek.setDate(today.getDate() - today.getDay());
    //             const endOfWeek = new Date(startOfWeek);
    //             endOfWeek.setDate(startOfWeek.getDate() + 6);
    
    //             return productDateObj >= startOfWeek && productDateObj <= endOfWeek;
    //         case "Ce mois":
    //             const isSameMonth = productDateObj.getMonth() === today.getMonth();
    //             const isSameYear = productDateObj.getFullYear() === today.getFullYear();
    //             return isSameMonth && isSameYear;
    //         case "Cette année":
    //             return productDateObj.getFullYear() === today.getFullYear();
    //             case "2019":
    //                 return productDateObj.getFullYear() === 2019;
    //             default:
    //                 return true;
    //     }
    // };
    
    // useEffect(() => {
    // setLoading(true);
    //     setState((prevState) => ({ ...prevState, messageErrors: "aucun produit" }));
    //     AfficheProductsService().getProduct()
    //         .then((res: any) => {
    //             let filtered = res.data.alldata.filter((pro: ArticleInfo) => {
    //                 const searchTerm = search.toLowerCase();
    //                 const designation = pro.Designation.toLowerCase();
    //                 const designationAr = pro.DesignationAr?.toLowerCase();
    //                 const refArticle = pro.RefArticle.toString().toLowerCase();
    //                 const sub = pro.LibelleSubstitut?.toString().toLowerCase();
    //                 const productPrice = parseFloat(pro.PrixVenteArticleHT);
    //                 const isSearchMatch =
    //                     searchTerm === "" ||
    //                     designation.includes(searchTerm) ||
    //                     designationAr?.includes(searchTerm) ||
    //                     refArticle.startsWith(searchTerm) ||
    //                     sub?.startsWith(searchTerm);
    //             });
    //             setFilteredProducts(filtered);
    //             setState((prevState: any) => ({ ...prevState, product: res.data.data }));
    //         })
    //         .catch((msg: any) =>
    //             setState((prevState: any) => ({
    //                 ...prevState,
    //                 messageErrors: msg.messageErrors,
    //             }))
    //         );
    //         setLoading(false);

    // }, [search]);
    

    const { product, messageErros } = state;
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = filteredProducts.slice(indexOfFirstRecord, indexOfLastRecord);
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredProducts.length / recordsPerPage); i++) {
        pageNumbers.push(i);
    }

    return <>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css"></link>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
        <div>

        <div className="title-app">MERCHSENTRY<br />
            <svg className="underline-title" width="149" height="5" viewBox="0 0 149 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.91077 0.821324C0.919613 0.821324 0.116124 1.62481 0.116124 2.61597C0.116124 3.60712 0.919613 4.41061 1.91077 4.41061V0.821324ZM146.679 4.41061H148.473V0.821324H146.679V4.41061ZM1.91077 4.41061H146.679V0.821324H1.91077V4.41061Z" fill="#D4B996" />
            </svg>
            
        <Link to ={"/visteurs/contactez-nous"}><span className="contact-icon"> Contactez nous <i className="bi bi-chat-square-text-fill"></i></span></Link>
        </div>
        <div className="container row height filter-Visiteur">
            <div className="col-md-6">
                <div className="form ">
                    <i className="fa fa-search" />
                    <input type="text" onChange={handleSearch} className="form-control form-input" placeholder="Recherch un produit , ref .." />
                    <i style={{position:"absolute",marginLeft:"95%"}} className="bi bi-sliders"></i>
                </div>
            </div>
        </div>
        <div className="content-visiteure">
            <div className="categorie">
                <form action="">
                    <input type="checkbox" /> <span className="piece-auto">Pièces automobiles</span>  <br />
                    <input type="checkbox" /> <span className="materiel-sou">Matériel de soudage</span>  <br />
                    <input type="checkbox" /> <span className="maintenance-rep">Maintenance et réparation</span>  <br />
                    <input type="checkbox" /> <span className="outils-ind">Outils industriels</span>  <br />
                    <input type="checkbox" />  <span className="equipement">Equipements</span> <br />
                    <input type="checkbox" /> <span className="lubrifiants">Lubrifiants</span>  <br />
                    <input type="checkbox" />  <span className="huiles">Huiles</span>
                </form>
                <div className="slider-container">
        <div className="slider-label">Prix</div>
        <input type="range" min="100" max="100000" value="100" className="slider" id="priceRange"/>
        <div className="slider-labels">
            <span>100 MAD</span>
            <span>100000 MAD</span>
        </div>
    </div>
            </div>
            {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{marginLeft:"27%", height: '250px', color: "#FFC400" }}>
          <div className="spinner-border custom-spinner" role="status">
          </div>
            <span className="loader-charg"> Chargement..</span> 
        </div>
        ) : (
            <div className="produit-content">
                <h3 className="box-title">Meilleures ventes <i className="bi bi-graph-up-arrow"></i></h3>

                <Link className="vp-page" to={'/visiteurs/articles'}>Voir plus</Link>
                {filteredProducts && filteredProducts.length > 0 ?
                    <div className="container_slide">
                        <CardContainer cards={filteredProducts} />
                    </div>
                    : product && product.length > 0 ? (
                        <div className="no-produit">
                            <i className="bi bi-emoji-neutral"></i><br />
                            <p>Malheureusement, on n‘a pas ce produit pour l’instant.</p><br />
                        </div>
                    ) : (
                        <div className="no-produit">
              <i className="bi bi-emoji-neutral"></i><br />
              <p>Malheureusement, aucun produit trouvé pour l'instant.</p><br />
            </div>
                    )}

                <Swiper
                    style={{ transform: 'translate3d(' + 1 + 'px, 0px, 0px)' }}
                    freeMode={true}
                    grabCursor={true}
                    className="container myswiper"
                    breakpoints={{
                        0: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        550: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        700: {
                            slidesPerView: 3,
                            spaceBetween: 10,
                        },
                    }}
                    navigation={{
                        prevEl: '.swiper-button-prev',
                        nextEl: '.swiper-button-next',
                    }}
                >
                </Swiper>

            </div>
        )}
        </div>
        <h5 className="prouit-p-v">Produits les plus <span className="vendus-page">vendus <i className="bi bi-arrow-up-circle-fill"></i> </span> </h5>
        {loading===true?(
            <div className="d-flex justify-content-center align-items-center" style={{ height: '250px', color: "#FFC400" }}>
          <div className="spinner-border custom-spinner" role="status">
          </div>
              <span className="loader-charg"> Chargement..</span>
        </div>
        ):(
          <>
          <div className="row mt-5">
{
    currentRecords.length > 0 ? currentRecords.map(pro => (
        <>
        <div className="col-lg-3 col-md-6 col-12 mb-3" >
<Link to ={`/visiteurs/articles/${pro.RefArticle[0]}`}>

            <div className="card_slid">
<img style={{width:"90%" , paddingLeft:"8%"}} src={pro.urlImage}
alt={pro.alt || 'Image'} />
<div className="box-visiteur">
<p>
  {pro.distance !== undefined && pro.distance !== null 
    ? `${pro.distance.toFixed(2)} km de chez vous`
    : "+100 km de chez vous"}
</p>
</div>
<div className="name-produit-visiteur">
<p>{pro.Designation}{pro.DesignationAr ? ` | ${pro.DesignationAr}` : ""}
</p>
<h5>{pro.PrixVenteArticleHT} MAD</h5>
<div className="brand">
                                        <img style={{ height: '30px' }} src={logoBrand} alt="" />
                                        <svg className="panier-box-visiteur" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        </svg>
                                    </div>
</div>
</div>

</Link>
        </div>
        </>

    )) : (
        <div className="no-produit">
              <i className="bi bi-emoji-neutral"></i><br />
              <p>Malheureusement, aucun produit trouvé pour l'instant.</p><br />
            </div>
    )
}

        </div>
        
        <div className="pagination mb-5">
<Link to ={`/visiteurs/articles`}>
              <button
                className="page-button"
              >
                Voir plus 
              </button>
</Link>

        </div>
          </>
        )
        }
            
        
        <Link to={"/visiteurs/panier"}>
        <div className="panier-visiteur-page" >
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-cart3" viewBox="0 0 16 16">
            <path style={{color:"black"}} d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l.84 4.479 9.144-.459L13.89 4zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
            </svg>
            {/* {localStorageData?.length > 0 ? <span className="num">{localStorageData?.length}</span> : ""} */}

            </div>
        </Link>
        </div>
    </>
}


