import axios, { AxiosError } from "axios";
import { ChangeEvent, FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import CreateMagasiEnligne from "../../Views/Utilisateure/visiteur/CreateMagasiEnligne";

export default function AddMagasinenligne(){
    const navigate = useNavigate();
    const [NomMagasin, setNomMagasin] = useState<string>("");
    const [Latitude, setLatitude] = useState<any>(0);
    const [Longitude, setLongitude] = useState<any>(0);
    const [nom, setnom] = useState<string>("");
    const [email, setemail] = useState<string>("");
    const [password, setpassword] = useState<string>("");
    const [tele, settele] = useState<string>("");
    const [adresse, setAdresse] = useState<string>("");
    const [messageError, setMessageError] = useState<string>("");
    const [messageSucess, setMessageSucess] = useState<string>("");

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("NomMagasin", NomMagasin);
    formData.append("nom", nom);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("tele", tele);
    formData.append("latitude", Latitude);
    formData.append("longitude", Longitude);
    formData.append("adress", adresse);

    try {
      await axios.post(`${process.env.REACT_APP_PHP_APP_URL}/magasin-enligne/create`, formData)
      .then(({data})=>{
        setMessageSucess('Votre commande est en cours de traitement et vous en serez informé par e-mail')
        const timeoutId = setTimeout(() => {
        setMessageSucess('')
        }, 4000);

      })
    } catch (error: AxiosError | any) {
        setMessageError('Veuillez saisir toutes les informations')
        const timeoutId = setTimeout(() => {
        setMessageError('')
        }, 3000);

      } 
      
    }
    return<>
    <CreateMagasiEnligne
        setAdress={setAdresse}
        adress={adresse}
        setNomMagasin={setNomMagasin}
        setnom={setnom}
        setemail={setemail}
        setpassword={setpassword}
        settele={settele}
        setMessageError={setMessageError}
        setMessageSucess={setMessageSucess}
        handleSubmit={handleSubmit}
        setLatitude={setLatitude}
        setLongitude={setLongitude}
        Latitude={Latitude}
        Longitude={Longitude}
        messageError={messageError}
        messageSucess={messageSucess}
        NomMagasin={NomMagasin}
        nom={nom}
        email={email}
        password={password}
        tele={tele}   
    />
    </>
}