import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import AfficheProductsService from "../../../Services/Admin/AfficheProductsService";
import { ArticleInfo } from "../../../Modeles/ArticleModel";
import Sidebare from '../Sidbare/Sidebare';

export default function AllProduit() {
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState<ArticleInfo[]>([]);
  const [alldata, setAlldata] = useState<ArticleInfo[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 30;
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);

  const fetchProducts = (pageNumber: number) => {
    setLoading(true);
    AfficheProductsService().getProductPagination(pageNumber)
      .then((res: any) => {
        setProducts(res.data.data);
        setAlldata(res.data.alldata); // Save all data for searching
        setTotalPages(res.data.last_page);
        setLoading(false);
      })
      .catch((err: any) => {
        console.error("Error fetching products: ", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchProducts(currentPage);
  }, [currentPage]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const filteredProducts = alldata.filter((pro: ArticleInfo) => {
    // Ensure RefArticle is an array
    const refs = Array.isArray(pro.RefArticle) ? pro.RefArticle : [pro.RefArticle];
  
    return (
      pro.Designation?.toLowerCase().includes(search.toLowerCase()) ||
      pro.PrixVenteArticleTTC?.toLowerCase().includes(search.toLowerCase()) ||
      refs.some(ref => ref?.toLowerCase().includes(search.toLowerCase())) || // Check all references
      pro.DesignationAr?.toLowerCase().includes(search.toLowerCase())
    );
  });
  const paginatedProducts = filteredProducts.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage);

  const renderProducts = () => {
    if (paginatedProducts && paginatedProducts.length > 0) {

      return paginatedProducts.map((pro: ArticleInfo) => (
        <div className="col-lg-3 col-md-6 col-12" key={pro.IdArticle}>
<Link className="gotoshow" to={`/articles/${pro.RefArticle[0]}`}>
<div className="box boxcomercial">
              <div className="slidImages bar1">
                <img src={pro.urlImage} alt={pro.Designation} />
                <p>{pro.Designation} {pro.DesignationAr ? `| ${pro.DesignationAr}` : ""}</p>
              </div>
              <div className="bar2">
                <p>LibelleFamArticle: <span>{pro.LibelleFamArticle || "N/A"}</span></p>
                <p>Reference: <span>{pro.RefArticle || "N/A"}</span></p>
              </div>
              <div className="bar3-admin">
                <div className="price"><p>{pro.PrixVenteArticleHT} MAD <span>/ P.U</span></p></div>
              </div>
            </div>
          </Link>
        </div>
      ));
    }else{
      return (
        <div className="no-produit">
          <i className="bi bi-emoji-neutral"></i>
          <br />
          <p>
            Malheureusement, on n‘a pas ce produit pour l’instant.
          </p>
          <br />
          <Link
            to={"https://api.whatsapp.com/send?phone=212661718081"}
            target="_blank"
            className="botton-remplir"
          >
            <button>
              <i className="bi bi-whatsapp"></i>Contactez-nous
            </button>
          </Link>
        </div>
      );
    }
  };

  return (
    <>
      <Sidebare />
      <div className='container'>
        <div className="container_div mt-5 rechAA" style={{ width: '100vw' }} >
          <div className="" style={{ display: 'flex', marginLeft: '-3%', alignItems: "center" }}>
            <div className="" style={{ width: '65vw' }}>
              <div className="position-relative">
                <i className="bi bi-search position-absolute" style={{ color: "#C5C5C5", top: '50%', fontSize: "1.4rem", transform: 'translateY(-50%)', left: '10px' }} />
                <input
                  type="text"
                  onChange={handleSearch}
                  className="form-control form-input pl-4"
                  placeholder="Recherch un produit, ref .."
                />
                <i className="bi bi-sliders fa-2x position-absolute" style={{ fontSize: "1.4rem", color: "#C5C5C5", top: '50%', transform: 'translateY(-50%)', right: '10px' }} />
              </div>
            </div>
            <div className="ml-4" style={{ marginLeft: '30px' }}>
              <Link to="/articles/categories/add"><button className="btnAjoutee">Ajouter Produit</button></Link>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '250px', color: "#FFC400" }}>
            <div className="spinner-border custom-spinner" role="status">
            </div>
              <span className="loader-charg">Chargement</span>
          </div>
        ) : (
          <>
            <div className="row">
              {renderProducts()}
            </div>
            <div className="pagination mb-5">
              <button
                className="page-button"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                précedent
              </button>
              <span className="page-info">Page {currentPage} of {Math.ceil(filteredProducts.length / recordsPerPage)}</span>
              <button
                className="page-button"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage * recordsPerPage >= filteredProducts.length}
              >
                Suivant
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
