import { Link } from "react-router-dom";
import SideBareMagasin from "../SideBareMagasin/SideBareMagasin";
import "./PanierMagasin.css";
import { useState, useEffect } from "react";
import PanierChrono from "../../../components/PanierChrono";

interface Product {
    id: string;
    name: string;
    urlImage: string;
    prix2: string;
    quantity: number;
    Remise : any
}

const deleteProduct = (id: string, updateProducts: (products: Product[]) => void) => {
    let arr: Product[] = [];
    const localStorageData = localStorage.getItem('products');
    if (localStorageData) {
        arr = JSON.parse(localStorageData);
    }

    // Correctly filter out the product with the given ID
    const updatedProducts = arr.filter(product => product.id !== id);
    localStorage.setItem('products', JSON.stringify(updatedProducts));
    updateProducts(updatedProducts);
    console.log("update",updatedProducts);
    
};

export default function PanierMagasin() {
    const [products, setProducts] = useState<Product[]>([]);
    const [isDivVisible, setDivVisible] = useState(true);
    // const startTimer = localStorage.getItem('startDate')

    const [Totale, setTotale] = useState<number>(0);

    useEffect(() => {
        const storedProducts = localStorage.getItem('products');
        if (storedProducts) {
            try {
                setProducts(JSON.parse(storedProducts));
            } catch (error) {
                console.error('Error parsing products from local storage', error);
            }
        }
    }, []);

    const handleDeleteProduct = (id: string) => {
        deleteProduct(id, (updatedProducts: any[]) => {
            setProducts(updatedProducts);
    
            if (updatedProducts.length === 0) {
                localStorage.removeItem('StartDate'); 
            }
    
            setMessage('Produit supprimé avec succès!');
            setTimeout(() => {
                setMessage('');
            }, 3000);
        });
    };


    const [message, setMessage] = useState('');

    const hundelVidePanier = () => {
        localStorage.removeItem('products');
        localStorage.removeItem('StartDate');
        setDivVisible(false);
        setProducts([]);
        setMessage('Panier vidé avec succès!' );
        setTimeout(() => {
            setMessage('');
        }, 3000);
    }


    const sumQty = () => {
        let total = 0;
        for (let i = 0; i < products.length; i++) {
            const QtyXprice = Number(products[i].prix2) * Number(products[i].quantity);
            total += QtyXprice;
        }
        setTotale(total);
    };

    useEffect(() => {
        sumQty();
    }, [products]);


    useEffect(() => {
        if (!products) {
            
        }
    }, [products])
    console.log("data panier",products)
    return (
        <>
            <SideBareMagasin />
            <div className="  container mt-5">
                <PanierChrono />
                <div className=" w-full d-flex justify-content-between align-items-center ">

                    <div>
                        <h2 className="mb-3">Mon panier ({products.length})</h2>
                    </div>
                    {
                        products.length>0?
                        <div>
                        <button onClick={() => hundelVidePanier()} className=" btn "> Vider le panier <i className="bi bi-trash"></i></button>
                    </div>
                        :""
                    }
                    
                </div>
                <div className="container mt-5">
                    {message && (
                       <div className="p-3 mb-2 bg-danger-subtle text-white">
                             {message} <i className="bi bi-check-lg"></i>
                        </div>
                    )}
                </div>

                {(isDivVisible && products) && products.length > 0 ? (
                    products.map((item, index) => (
                        <div key={index} className="card mt-5 mb-5">
                            <div className="InfoPrp">
                                <button
                                    className="supprimer"
                                    onClick={() => handleDeleteProduct(item.id)}
                                >
                                    Supprimer ce produit <i className="bi bi-x-lg"></i>
                                </button>
                                <h4>{item.prix2} MAD</h4>
                            </div>
                            <div className="infoPP">
                                <img src={item.urlImage}/>
                                <h5>{item.name}</h5>
                                <div className="quantite">
                        <h6 className="trp">taux de remise est : {item.Remise}%</h6>
                                    <label>
                                        Quantité :
                                        <input type="number" min={1} value={item.quantity} readOnly />
                                    </label>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <h4 className="text-center">No products available.</h4>
                )}
                 {
                            products.length>0 ? 
                <div className="w-75 d-flex mx-auto align-items-center h-25" style={{ "height": '100px' }} >
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <div className="mr-3 mt-5">
                            <h3>Total :</h3>
                            
                        </div>
                       
                            <div>
                        <h3><b>{Totale}</b> MAD</h3>

                        </div>
                       
                        
                    </div>
                </div>
                     :""
                    }
            </div>
        </>
    );
}
