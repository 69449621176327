import { useState, useRef, useEffect } from "react";
import "./new-page.css";
import { Link } from "react-router-dom";
import { Magasinenligne } from "../../../Modeles/MagasinModel";
import { Libraries, LoadScript, StandaloneSearchBox } from "@react-google-maps/api";

const CreateMagasiEnligne: React.FC<Magasinenligne> = ({
  messageError,
  messageSucess,
  NomMagasin,
  nom,
  adress,
  email,
  password,
  Latitude,
  Longitude,
  setLatitude,
  setLongitude,
  handleSubmit,
  setNomMagasin,
  setAdress,
  setnom,
  setemail,
  setpassword,
  settele,
  setMessageError,
  setMessageSucess,
  tele,
}) => {

  const searchBoxRef = useRef<google.maps.places.SearchBox | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const libraries: Libraries = ["places"]; // Type the libraries array correctly

  const handlePlaceChanged = () => {
    const places = searchBoxRef.current?.getPlaces();
    if (places && places.length > 0) {
        const place = places[0];
        const formattedAddress = place.formatted_address || "";
        setAdress(formattedAddress);
        if (place.geometry && place.geometry.location) {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng(); 
            setLatitude(lat);
            setLongitude(lng);
            console.log(Latitude);
            console.log(Longitude);

        }

    }
};

  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css" />
      <div className="d-flex h-100">
        <div className="login100-more-new">
          <div className="col text-center item-center text-merch">
            <div className="title parag">
              <h1>MERCHSENTRY</h1>
            </div>
            <div className="para-cnx">
              <p className="parag">
                libérer l'efficacité : là où la précision <br />
                mécanique rencontre le contrôle numérique
              </p>
            </div>
          </div>
        </div>

        <div className="w-75 h-100 carte-merchsentry">
          <div>
            <form className="login100-form-new" onSubmit={handleSubmit}>
              <h4 style={{ marginTop: "-60px", textAlign: "center" }}>Créer votre magasin en ligne</h4>
              <span className="iconRetour">
                <i onClick={handleGoBack} className="bi bi-arrow-left-short"></i>
              </span>

              <br /><br />
              <div className="row">
                {messageError && messageError !== " " && (
                  <div className="alert alert-danger">
                    <button type="button" className="close" data-dismiss="alert" aria-hidden="true">
                      ×
                    </button>
                    <span className="glyphicon glyphicon-info-sign"></span> <strong>Message</strong>
                    <hr className="message-inner-separator" />
                    <p>{messageError}</p>
                  </div>
                )}

                {messageSucess && messageSucess !== "" && (
                  <div className="alert alert-success" role="alert">
                    <i className="bi bi-check-circle-fill"></i> {messageSucess}
                  </div>
                )}

                <div className="col-6">
                  <label className="form-label">Nom Complet</label>
                  <input
                    onChange={(e) => setnom(e.target.value)}
                    type="text"
                    className="form-control mb-3"
                    placeholder="Nom"
                    style={{ width: "90%" }}
                  />
                </div>
                <div className="col-6">
                  <label className="form-label">Nom Magasin</label>
                  <input
                    onChange={(e) => setNomMagasin(e.target.value)}
                    type="text"
                    className="form-control mb-3"
                    placeholder="Prénom"
                    style={{ width: "90%" }}
                  />
                </div>

                <div className="col-6">
                  <label className="form-label">Adresse E-mail</label>
                  <input
                    onChange={(e) => setemail(e.target.value)}
                    type="text"
                    className="form-control mb-3"
                    placeholder="Adresse"
                    style={{ width: "90%" }}
                  />
                </div>
                <div className="col-6">
                  <label className="form-label">Numéro de téléphone</label>
                  <input
                    onChange={(e) => settele(e.target.value)}
                    type="text"
                    className="form-control mb-3"
                    placeholder="Numéro de téléphone"
                    style={{ width: "90%" }}
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label">Localisation</label>
                  <LoadScript googleMapsApiKey="AIzaSyBmLZdgzjt2mfMeAb-iZTkUeNYWqPU1FoE" libraries={libraries}>
                    <StandaloneSearchBox
                      onLoad={(ref) => (searchBoxRef.current = ref)}
                      onPlacesChanged={handlePlaceChanged}
                    >
                      <input
                        type="text"
                        placeholder="Enter your address"
                        ref={inputRef}
                        style={{ width: "95%" }}
                      />
                    </StandaloneSearchBox>
                  </LoadScript>
                </div>

                <div className="col-6">
                  <label className="form-label">Mot de passe</label>
                  <input
                    onChange={(e) => setpassword(e.target.value)}
                    type="text"
                    className="form-control mb-3"
                    placeholder="Mot de passe"
                    style={{ width: "90%" }}
                  />
                </div>
                <div className="col-6">
                  <label className="form-label">Confirmation mot de passe</label>
                  <input
                    onChange={(e) => setpassword(e.target.value)}
                    type="text"
                    className="form-control mb-3"
                    placeholder="Confirmation mot de passe"
                    style={{ width: "90%" }}
                  />
                </div>
              </div>
              <button className="btn-create">Créer</button> <br /> <br />
              <Link to="/">
                <a href="" className="text-dark alert-link">
                  Se connecter
                </a>
              </Link>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateMagasiEnligne;
