import React from 'react';
import './NotFound.css';
import{Link} from 'react-router-dom';

function NotFound() {
    const handleGoBack = () => {
        window.history.back();
    };
    return (
        <div className='container' >
            <div className='oopss'>
                <div className='error-text'>
                    <img src="https://cdn.rawgit.com/ahmedhosna95/upload/1731955f/sad404.svg" alt="404" />
                    <span className='titl-nf'>404 PAGE</span>
                    <p className="p-a">
                    ! Page non trouvable
                    </p>
                    <button className='btn-retour-nf' onClick={handleGoBack}>
                        Retour <i className="bi bi-arrow-left-circle-fill" style={{fontSize:"1rem"}}></i>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default NotFound;