import { SetStateAction, useContext, useEffect, useState } from "react";
import { MagasinContext } from "../../Context/MagasinContext";
import axios, { AxiosError } from "axios";
import { useParams } from "react-router-dom";
import Demande from "../../Views/Magasin/HomeMagasin/Demande";
export default function DemandeArticleByRef(){
    const [Designation,setDesignation] = useState<string>("");
    const [IdArticle,setIdArticle] = useState<number>(0);
    const [RefArticle,setRefArticle] = useState<string>("");
    const [quantité,setQuantité] = useState<number>(0);
    const [LibelleSubstitut,setLibelle] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(true);


    const MagasinId = localStorage.getItem('MagasinId');  
    const { ref } = useParams<{ ref?: string}>(); 
    useEffect(()=>{
        fetchProduct();
    },[])
    const fetchProduct=async()=>{
      const url =`${process.env.REACT_APP_PHP_APP_URL}/articles/${ref}` ;
        try {
          await axios.get(url)
          .then(({data})=>{
              setDesignation(data.Designation);
              setIdArticle(data.IdArticle);
              setRefArticle(data.RefArticle);
              setQuantité(data.QteQtock); 
                setLoading(false)
                setLibelle(data.LibelleSubstitut)

        })} catch (error: AxiosError | any) {
          if (error.response?.status === 442) {
          console.log("good data");
          setLoading(false)

    
          } else {
            console.log("error");
          }
        }
          
      }
      return<>
      <Demande
              Designation={Designation}
              IdArticle={IdArticle}
              RefArticle={RefArticle}
              quantité={quantité} PrixVenteArticleTTC={""} Description={""} Unite={""} setDesignation={function (value: SetStateAction<string>): void {
                  throw new Error("Function not implemented.");
              } } setNomClient={function (value: SetStateAction<string>): void {
                  throw new Error("Function not implemented.");
              } } setAdresse={function (value: SetStateAction<string>): void {
                  throw new Error("Function not implemented.");
              } } setTotalCommandeHT={function (value: SetStateAction<string>): void {
                  throw new Error("Function not implemented.");
              } } setTotalRemise={function (value: SetStateAction<string>): void {
                  throw new Error("Function not implemented.");
              } } setPrixVenteArticleTTC={function (value: SetStateAction<string>): void {
                  throw new Error("Function not implemented.");
              } } setDescription={function (value: SetStateAction<string>): void {
                  throw new Error("Function not implemented.");
              } } setImage={function (value: SetStateAction<File | null>): void {
                  throw new Error("Function not implemented.");
              } } setstock={function (value: SetStateAction<string>): void {
                  throw new Error("Function not implemented.");
              } } setRefARticle={function (value: SetStateAction<string>): void {
                  throw new Error("Function not implemented.");
              } } setUnite={function (value: SetStateAction<string>): void {
                  throw new Error("Function not implemented.");
              } } setprix_ht_2_magasin={function (value: SetStateAction<string>): void {
                  throw new Error("Function not implemented.");
              } } setprix_ht_3_magasin={function (value: SetStateAction<string>): void {
                  throw new Error("Function not implemented.");
              } } setprix_ht_1_magasin={function (value: SetStateAction<string>): void {
                  throw new Error("Function not implemented.");
              } } setquantité={function (value: SetStateAction<string>): void {
                  throw new Error("Function not implemented.");
              } }      />
      </>
}