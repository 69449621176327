import produit from "../../Admin/IMG/1.png";
import { Link } from "react-router-dom";
import "./afficheProduitmagasin.css";
import SideBareMagasin from "../SideBareMagasin/SideBareMagasin";
import { ArticleInfo } from "../../../Modeles/ArticleModel";
import { useContext, useState } from "react";
import { MagasinContext } from "../../../Context/MagasinContext";

interface Product {
    id: string;
    name: string;
    urlImage: string;
    quantity: number;
    Remise:string;
    prix2:number;
}

const AfficheProduitsMagasin: React.FC<ArticleInfo> = ({
    IdArticle,
    Designation,
    prixTtc,
    PrixVenteArticleTTC,
    Description,
    urlImage,
    stock,
    quantité,
    Remise,
    RefArticle,
    LibelleSubstitut,
    prix_ht_2_magasin,
    prix2,
    prix_ht_1_magasin,
    loading,
}) => {
    console.log(quantité)
    const [products, setProducts] = useState<Product[]>([]);
    const [value, setValue] = useState(1);
    const [message, setMessage] = useState('');
    const handleGoBack = () => {
        window.history.back();
    };
    
    const magasinContext = useContext(MagasinContext);
    const localStorageData = JSON.parse(localStorage.getItem('products') || '[]');

    const MagasinId = localStorage.getItem('MagasinId');
    const id = MagasinId || magasinContext.id?.id;
    
    const addProductToPanier = (id: string,image: string, name: string, prix22: number) => {
        setProducts((prevProducts: Product[]) => {
            const newProduct: Product = { id, name, urlImage: urlImage, prix2:prix2, quantity: value ,Remise:Remise};
            
            if (newProduct.quantity !== 0) {
                let arr: Product[] = [];
                const localStorageData = localStorage.getItem('products');
                if (localStorageData) {
                    arr = JSON.parse(localStorageData);
                }
                const productIndex = arr.findIndex((product: Product) => product.id === id);
                if (productIndex !== -1) {
                    arr[productIndex].quantity += Number(value);
                } else {
                    arr.push(newProduct);
                }
                localStorage.setItem('products', JSON.stringify(arr));
                setMessage('Product added to panier');
                setTimeout(() => {
                    setMessage('');
                }, 3000);
                localStorage.setItem('StartDate', new Date().toISOString());
            }
            return prevProducts;
        });
    };

    return (
        <>
            <SideBareMagasin />
            {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '550px', color:"#FFC400" }}>
            <div className="spinner-border custom-spinner" role="status">
            </div>
            <span className="loader-charg">Chargement</span>
          </div>
        ) : (
            <div className="container produitCard mt-5">
                <i onClick={handleGoBack} className="bi bi-arrow-left-short"></i>

                <div className="barTitle paniercommercial">
                    <h3>{Designation}</h3>
                    <Link style={{ background: 'none' }} className="paniercommercialicon" to={`/magasins/${id}/panier`} >
                        <i className="bi bi-cart3 icon">{localStorageData?.length > 0 ? <span>{localStorageData?.length}</span> : ""}</i>
                    </Link>
                </div>
                <div className="container mt-5">
                    {message && (
                        <div className="p-3 mb-2 bg-success-subtle text-success-emphasis text-white">
                            {message} &nbsp;<i className="bi bi-check-lg"></i>
                        </div>
                    )}
                </div>

                <div className="cardItem mt-5">
                    <img style={{width:"30%" , height:"25%"}} src={urlImage} alt="" />
                    <div className="ItemsInfo">
                        <h2 className="mb-5">{PrixVenteArticleTTC} MAD <span>/P.U</span></h2>
                        <div className="3prix mb-5">
                        {/* <h5 className="message-remise">{Remise}% de Remise</h5> */}
                            <h6>Prix Net HT : <span>{PrixVenteArticleTTC}</span></h6>
                            <h6>
  Substitut :
  <span className="reference-item">
    {(Array.isArray(LibelleSubstitut) && LibelleSubstitut.length === 1 && LibelleSubstitut[0].trim() === "") || !LibelleSubstitut
      ? <span>N/A</span>
      : LibelleSubstitut}
  </span>
</h6>        
<h6>
  Qte Stock: : 
  <span className="quantity-item">
    {quantité !== undefined && quantité === 0 ? (
      <>
        <span className="stock-épuisé mb-3"> stock épuisé <i className="bi ise bi-exclamation-lg"></i></span><br /><br />
        <Link to={`/magasins/${id}/demande/articles/${RefArticle}`} className="btn-da mt-5"><i className="bi ida bi-plus-square"></i> Demander l'article</Link>
      </>
    ) : (
         quantité
    )}
  </span>
</h6>
                        </div>
                        {quantité !== undefined && quantité === 0 ? (
    ""
    ) : (
        <>
        <div className="quantity">
        <h6>Quantité <input className="inputQ" value={value} max={quantité} onChange={(e: any) => setValue(Number(e.target.value))} type="number" min={1} /> </h6>
    </div>
    <button onClick={() => addProductToPanier(RefArticle, produit, Designation, prix2)} className="ajouterP">Ajouter au panier</button>
        </>
    )}

                    </div>
                </div>

                <div className="sectionDesc">
                    <h5>Réference : <span>{RefArticle}</span></h5>
                </div>
            </div>
        )}
        </>
    );
};

export default AfficheProduitsMagasin;
