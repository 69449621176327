import React from 'react'
import Card from './Card';
import './CardStyle.css'

const CardContainer = (props) => (
    <div className="cards-container">
        {
            props.cards.map((card) => (
                <Card IdArticle={card.IdArticle}
                    PrixVenteArticleTTC={card.PrixVenteArticleTTC}
                    Designation={card.Designation}
                    DesignationAr={card.DesignationAr}
                    urlImage={card.urlImage}
                    RefArticle={card.RefArticle}
                    PrixVenteArticleHT={card.PrixVenteArticleHT}



                />
            ))

            // <SwiperSlide>
            //     <Link to={`/visiteurs/articles/${pro.IdArticle}`}>
            //         <div className="produit-visiteur">
            //             <div className="item-visiteur">
            //                 <img src={imgProduit} alt="" />
            //                 <div className="box-visiteur">
            //                     <p>À 10 km de chez vous</p>
            //                 </div>
            //                 <div className="name-produit-visiteur">
            //                     <p>{pro.Designation}</p>
            //                     <svg className="etoile-visiteur" width="63" height="14" viewBox="0 0 63 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            //                         <path d="M6.30599 0.668701L7.70929 4.98761H12.2505L8.57657 7.65684L9.97987 11.9758L6.30599 9.30652L2.6321 11.9758L4.0354 7.65684L0.361519 4.98761H4.90269L6.30599 0.668701Z" fill="#FAE315" />
            //                         <path d="M18.8068 0.668701L20.2101 4.98761H24.7513L21.0774 7.65684L22.4807 11.9758L18.8068 9.30652L15.1329 11.9758L16.5362 7.65684L12.8623 4.98761H17.4035L18.8068 0.668701Z" fill="#FAE315" />
            //                         <path d="M31.3076 0.668701L32.7109 4.98761H37.2521L33.5782 7.65684L34.9815 11.9758L31.3076 9.30652L27.6338 11.9758L29.0371 7.65684L25.3632 4.98761H29.9043L31.3076 0.668701Z" fill="#FAE315" />
            //                         <path d="M43.8085 0.668701L45.2118 4.98761H49.753L46.0791 7.65684L47.4824 11.9758L43.8085 9.30652L40.1346 11.9758L41.5379 7.65684L37.864 4.98761H42.4052L43.8085 0.668701Z" fill="#FAE315" />
            //                         <path d="M56.3088 0.668701L57.7121 4.98761H62.2533L58.5794 7.65684L59.9827 11.9758L56.3088 9.30652L52.6349 11.9758L54.0382 7.65684L50.3643 4.98761H54.9055L56.3088 0.668701Z" fill="#FAE315" />
            //                     </svg>
            //                     <h5>{pro.PrixVenteArticleTTC} MAD</h5>
            //                     <div className="brand">
            //                         <img src={logoBrand} alt="" />
            //                         <svg className="panier-box-visiteur" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            //                             <path fill-rule="evenodd" clip-rule="evenodd" d="M23.9654 21.3204H12.2734C10.7366 21.3204 9.93303 20.3963 9.71205 18.8796L8.15513 8.25234H5.01115C4.50892 8.25234 4.06696 7.81037 4.06696 7.28805C4.06696 6.77577 4.50892 6.33381 5.01115 6.33381H8.45646C9.57142 6.33381 9.91294 6.77577 10.0536 7.64966L10.2143 8.73448H19.6462C19.606 9.04586 19.5859 9.3472 19.5859 9.65859C19.5859 13.2947 22.5089 16.2278 26.2355 16.268L26.1451 16.4488C25.7634 17.2122 25.0803 17.634 24.0458 17.634H11.4899L11.6507 18.6787C11.731 19.191 12.0223 19.5224 12.5045 19.5224H23.9654C24.4375 19.5224 24.8694 19.8941 24.8694 20.4164C24.8694 20.9488 24.4375 21.3204 23.9654 21.3204ZM13.0971 26.3628C12.1228 26.3628 11.3393 25.5793 11.3393 24.605C11.3393 23.6307 12.1228 22.8472 13.0971 22.8472C14.0714 22.8472 14.8549 23.6307 14.8549 24.605C14.8549 25.5793 14.0714 26.3628 13.0971 26.3628ZM22.3783 26.3628C21.404 26.3628 20.6105 25.5793 20.6105 24.605C20.6105 23.6307 21.404 22.8472 22.3783 22.8472C23.3527 22.8472 24.1261 23.6307 24.1261 24.605C24.1261 25.5793 23.3527 26.3628 22.3783 26.3628Z" fill="#1C1C1E" />
            //                             <path fill-rule="evenodd" clip-rule="evenodd" d="M26.1953 14.8416C29.0078 14.8416 31.3683 12.4912 31.3683 9.65859C31.3683 6.826 29.0379 4.4856 26.1953 4.4856C23.3627 4.4856 21.0223 6.826 21.0223 9.65859C21.0223 12.5012 23.3627 14.8416 26.1953 14.8416ZM23.6038 8.96551C23.192 8.96551 22.9107 9.24676 22.9107 9.65859C22.9107 10.0805 23.192 10.3517 23.6038 10.3517H25.5022V12.2601C25.5022 12.672 25.7734 12.9432 26.1953 12.9432C26.6172 12.9432 26.8884 12.672 26.8884 12.2601V10.3517H28.7868C29.1987 10.3517 29.4799 10.0805 29.4799 9.65859C29.4799 9.24676 29.1987 8.96551 28.7868 8.96551H26.8884V7.07711C26.8884 6.66528 26.6172 6.38403 26.1953 6.38403C25.7734 6.38403 25.5022 6.66528 25.5022 7.07711V8.96551H23.6038Z" fill="#007ACC" />
            //                         </svg>
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            //     </Link>
            // </SwiperSlide>
        }
    </div>
);

export default CardContainer