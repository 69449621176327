import { useContext, useEffect, useState } from 'react';
import SideBareMagasin from '../SideBareMagasin/SideBareMagasin';
import Chart from "react-apexcharts";
import { Link, useParams } from 'react-router-dom';
import "./afficheCommercial.css";
import { MagasinContext } from '../../../Context/MagasinContext';
import GetCommercialById from '../../../Services/Admin/GetCommercialById';
import { CommercialInfo } from '../../../Modeles/Commercial';
import GetGommercialById from '../../../Services/Admin/GetCommercialById';

export interface CommandeType {
  Commercial: CommercialInfo[],
  messageErrosCommercial: string,
}

export default function AfficheCommercial() {
  const handleGoBack = () => {
    window.history.back();
  };

  const [stateCommercial, setstateCommercial] = useState<CommandeType>({
    Commercial: [] as CommercialInfo[],
    messageErrosCommercial: "Accune commande",
  });

  const { id } = useParams();
  const magasinContext = useContext(MagasinContext);
  const MagasinId = localStorage.getItem('MagasinId');
  const idMag = MagasinId || magasinContext.id?.id;


useEffect(()=>{
  setstateCommercial({...stateCommercial })
    GetGommercialById(id,idMag).GetCommercial()
      .then((res)=>setstateCommercial({...stateCommercial  , Commercial:res.data})

      )
      .catch(msg=>setstateCommercial({...stateCommercial  , messageErrosCommercial:msg.messageErrosCommercial}))
},[]);
const {Commercial , messageErrosCommercial} = stateCommercial
  console.log("Commercial: ", Commercial);

  const [state, setStateChart] = useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre']
      }
    },
    series: [
      {
        name: "Vendu",
        data: [20, 22, 54, 0, 0, 0, 0, 0, 0],
        color: '#886FA3',
      },
      {
        name: "Annulé",
        data: [3, 15, 0, 20, 0, 0, 0, 0, 0],
        color: '#28CDAA'
      }
    ]
  });

  return (
    <>
      <SideBareMagasin />
      <div className="container">
        {Commercial && Commercial.length > 0 ? Commercial.map(com => (
          <>
            <div className="barRetour mt-5">
              <span className="iconRetour">
                <Link to="" onClick={handleGoBack}><i className="bi bi-arrow-left-short"></i></Link>
              </span>
              <div className="marque">
                <h4>{com.prenom} {com.Nom}</h4>
              </div>
            </div>

            <div className="inputForm mb-5">
              <div className="row mb-3">
                <div className="col">
                  <span>Nom</span>
                  <input type="text" defaultValue={com.Nom} className="form-control" aria-label="First name" />
                </div>
                <div className="col">
                  <span>Prénom</span>
                  <input type="text" className="form-control" value={com.prenom} aria-label="Last name" />
                </div>
              </div>
                <div className="row mb-3">
                <div className="col">
                  <span>Email</span>
                  <input type="text" className="form-control" defaultValue={com.email} aria-label="First name" />
                </div><div className="col">
                  <span>Password</span>
                  <input type="text" className="form-control" defaultValue={com.password} aria-label="First name" />
                </div>
                </div>
              <div className="row mb-3">
                <div className="col">
                  <span>Numéro de téléphone</span>
                  <input type="text" defaultValue={com.télephone} className="form-control" aria-label="First name" />
                </div>
                <div className="col">
                  <span>Remise</span>
                  <input type="text" className="form-control" value={com.RemiseGlobale} aria-label="Last name" />
                </div>
              </div>
              <div className="btnAjouteCommercial mt-5">
          <button id="bac" className="buttonAjouter" type="submit">Modifier</button>
          </div>
            </div>
            <div className="texth4">
              <p>Les commandes vendues ou annulées pendant les 6 derniers mois</p>
            </div>
            <Chart
              className="chart mb-5"
              options={state.options}
              series={state.series}
              type="line"
              width="470"
            />
          </>
        )) : <p>{messageErrosCommercial}</p>}
      </div>
    </>
  );
}
